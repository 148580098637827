@import './custom.css';
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] {
    -moz-appearance:textfield;
}

@media (max-width: 480px) {
  .container {
    margin: 24px 12px !important;
  }  
}