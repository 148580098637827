@import "../../styles/base/_variables.scss";
@import "../../styles/base/_mixins.scss";
.StepCounter{
  font-family: $global-font-family;
  font-weight: 300;
  font-size: 12px;
  line-height: 1.25;
  text-align: right;
  color: #4A91E1;
}
