@import '../../styles/base/_variables.scss';
@import '../../styles/base/_mixins.scss';

.HeaderNew {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #fff;
  @include breakpoint(xl) {
    box-shadow: 0px 2px 3px rgba(85, 99, 118, 0.1);
    // transform: matrix(1, 0, 0, -1, 0, 0);
  }
  .wrapper {
    max-width: 100%;
  }
}
.HeaderNew-container {
  background-color: #fff;
  @include breakpoint(lg-down) {
    position: relative;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px 0;
  min-height: $header-height-xl;
  @include breakpoint(sm-down) {
    padding: 0 30px 0;
    min-height: $header-height;
  }
}
.header-submenu {
  display: flex;
  min-height: $header-height-xl;
  .logo-container {
    margin: 0 !important;
  }
  justify-content: space-between;
  .left-outline-container {
    display: none;
  }
  .user-select {
    display: block;
  }
  @include breakpoint(xl) {
    padding: 0 110px 0;
  }
}

.left-outline-container {
  @include breakpoint(xl) {
    display: none;
  }
}
.logo-container {
  @include breakpoint(lg-down) {
    margin: 0 auto;
  }
  .logo {
    width: 109px;
    height: auto;
    @include breakpoint(xl) {
      width: 89px;
      height: auto;
    }
  }
}
.user-select {
  @include breakpoint(lg-down) {
    display: none;
  }
}
.counter-container {
  @include breakpoint(lg-down) {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translate(0, -50%);
  }
  @include breakpoint(xl) {
    display: none;
  }
}
.mob-menu-btn {
  @include breakpoint(lg-down) {
    @include outline(0);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    position: absolute;
    width: 38px;
    height: 38px;
    left: 30px;
    top: 50%;
    transform: translate(0, -50%);
    background: transparent;
    border: none;
    box-shadow: none;
    border-radius: 0;
  }
  @include breakpoint(xl) {
    display: none;
  }
}
.user-select-btn {
  @include outline(0);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: transparent;
  border: none;
  box-shadow: none;
  border-radius: 0;
  .user-down-arrow {
    margin-left: 20px;
    path {
      stroke: #556376;
      transition: stroke 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
  &:hover,
  &:focus {
    .user-down-arrow {
      path {
        stroke: #74b1ed;
      }
    }
  }
}
.ant-btn.ant-btn-text.collapse-btn-bar {
  color: white !important;
  background-color: white;
  &::after,
  &::before,
  &:hover,
  &:focus {
  }
}
.collapse-btn-bar,
.collapse-item.user-item {
  @include breakpoint(md) {
    display: none !important;
  }
}
.collapse-btn-user {
  font-size: 12px;
  font-weight: 500;
  color: #9aa8b8;
  display: none;
  @include breakpoint(md) {
    display: block;
  }
}
.menu-collapse {
  border-top: 1px solid #eee;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 30px 0;
  margin: 0;
  list-style: none;
  .collapse-item {
    width: 150px;
    @include breakpoint(xl) {
      width: 177px;
    }
    padding: 0px 4px 0px;
    display: flex;
    justify-content: center;
    position: relative;
    .ant-btn.collapse-btn {
      width: inherit;
      font-size: 12px;
      font-weight: 600;
      color: #9aa8b8;
      border-radius: 0;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 25%;
        display: block;
        width: 50%;
        height: 100%;
        z-index: -1;
        -webkit-transform: scaleX(0.3);
        transform: scaleX(0.3);
        opacity: 0;
        transition: all 0.3s;
        border-bottom: 3px solid #4a90e2;
      }
      &.active,
      &:hover {
        color: #4a90e2;
        &::after {
          opacity: 1;
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
          transition: -webkit-transform 0.6s
              cubic-bezier(0.08, 0.35, 0.13, 1.02),
            opacity 0.4s;
          transition: transform 0.6s cubic-bezier(0.08, 0.35, 0.13, 1.02),
            opacity;
        }
      }
    }
  }
}
.HeaderNew {
  @include breakpoint(sm-down) {
    // height: 100%;
    display: block;
    background-color: unset;
    .header-submenu {
      background-color: #fff;
    }
    .content-menu-collapse,
    .wrapper {
      height: inherit;
    }
    .content-menu-collapse {
      position: fixed;
      width: 100%;
      height: 100%;
      transform: translateX(100%);
      transition: transform 0.8s ease-in-out;
    }
    .content-menu-collapse.show {
      transform: translateX(0);
      transition: transform 0.8s ease-in-out;
    }
    .menu-collapse {
      // display: none;
      justify-content: flex-start;
      background-color: #fff;
      height: inherit;
      flex-direction: column;
      padding: 0;
      .collapse-item {
        width: 100%;
        .ant-btn.collapse-btn {
          text-align: left;
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

.ic-mekar{
  height: 12px;
  width: 12px;
  margin-right: 12px;
}