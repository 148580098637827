@import "../styles/base/_variables.scss";
@import "../styles/base/_mixins.scss";
// .DashboardLayout{
//   padding: 117px 0 40px; 
//   min-height: 100vh;
//   @include breakpoint(xl){
//     padding: 88px 0 90px; 
//   } 
// }
// .DashboardLayout-container{
//   padding: 0 30px 0;
//   @include breakpoint(xl){
//     padding: 0 50px 0;
//   }
// }
.DashboardLayout{
  padding: 108px 30px 40px; 
  min-height: 100vh;
  @include breakpoint(xl){
    padding: 88px 50px 90px; 
  } 
  &.flex-v100-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.title-layout{
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}
.layout-v2{
  max-width: 100% !important;
  margin: 0 !important;
  background-color: #f6f9fa;
    padding: 148px 50px 40px !important;
  @include breakpoint(sm-down) {
    padding: 108px 30px 40px !important;
  }
  @include breakpoint(xl) {
    padding: 148px 110px 40px !important;
  }
}